import React, { useState } from "react";
import "./MailchimpNewsletter.css";
import MailchimpSubscribe from "react-mailchimp-subscribe";

const CustomForm = ({ status, message, onValidated }) => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    email &&
      firstName &&
      lastName &&
      email.indexOf("@") > -1 &&
      onValidated({
        MERGE0: email,
        MERGE1: firstName,
        MERGE2: lastName,
      });
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <input
        onChange={(e) => setEmail(e.target.value)}
        type="text"
        placeholder="E-Mail Adresse"
        className="footer-form"
        value={email}
        required
      />
      <input
        onChange={(e) => setFirstName(e.target.value)}
        type="text"
        placeholder="Vorname"
        className="footer-form"
        value={firstName}
      />
      <input
        onChange={(e) => setLastName(e.target.value)}
        type="text"
        placeholder="Nachname"
        className="footer-form"
        value={lastName}
      />
      <input
        className="submit-newsletter"
        label="subscribe"
        type="submit"
        value="Abonnieren"
        formValues={[email, firstName, lastName]}
      />
      {status === "sending" && (
        <div className="mc__alert mc__alert--sending">Wird gesendet...</div>
      )}
      {status === "error" && (
        <div
          className="mc__alert mc__alert--error"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === "success" && (
        <div className="mc__alert mc__alert--success">
          Erfolgreich abonniert!
        </div>
      )}
    </form>
  );
};

const MailchimpNewsletter = (props) => {
  const postUrl = `https://dt-dentalservice.us14.list-manage.com/subscribe/post?u=67226eeb13a3c51d9eb716bda&id=ff2a372ab0`;
  return (
    <div className="mc__form-container">
      <MailchimpSubscribe
        url={postUrl}
        render={({ subscribe, status, message }) => (
          <CustomForm
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
          />
        )}
      />
    </div>
  );
};

export default MailchimpNewsletter;
