import "./Aktionen.css";
import glass from "../../images/glass.png";
import { HiDownload } from "react-icons/hi";
import { useEffect, useState } from "react";
import { getAktion } from "../../services";

function Aktionen() {
  const [aktionenData, setAktionData] = useState([]);
  const [isFetched, setIsFetched] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const result = await getAktion();

      setAktionData(result);
      setIsFetched(true);
      console.log(result);
    };

    fetchData();
  }, []);

  return (
    <div className="aktionen" id="aktionen">
      <div className="aktionen-top"></div>
      <div className="image">
        <div className="aktionen-wrap">
          <div className="aktionen-wrapper">
            <div className="aktionen-side1"></div>
            <div className="aktionen-mid">
              <img src={glass} alt="Glass" className="glass-img" />
            </div>
            <div className="aktionen-side2"></div>
          </div>
          <div className="aktionen-bohrer">
            <div className="aktionen-header blue">
              <h3>PRODUKT</h3>
              <h3>AKTIONEN</h3>
            </div>
            <a
              href={isFetched && aktionenData[0].node.flyer.url}
              target="_blank"
              className="aktionen-link"
            >
              <div className="aktionen-flyer">
                <h4>ANGEBOTS FLYER</h4>
                <HiDownload className="aktionen-icon" />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Aktionen;
