import "./Katalog.css";
import katalog from "../../images/DT-Katalog.png";
import { MdDesktopMac } from "react-icons/md";
import { HiDownload } from "react-icons/hi";
import KatalogFile from "../../files/120221_DT_Katalog (3).pdf";

function Katalog() {
  return (
    <div className="katalog">
      <div className="katalog-wrapper katalog-over">
        <div className="katalog-kat">
          <img src={katalog} alt="" className="katalog-image" />
        </div>
        <div className="katalog-header">
          <h3>HIER GEHT‘S ZUM KATALOG!</h3>
          <p>
            Online-blättern{" "}
            <a href={KatalogFile} target="_blank" rel="noreferrer">
              <span className="katalog-icon">
                <MdDesktopMac />
              </span>{" "}
            </a>
            oder direkt downloaden{" "}
            <a href={KatalogFile} download>
              <span className="katalog-icon">
                <HiDownload />
              </span>{" "}
            </a>
          </p>
        </div>
        <div className="katalog-div"></div>
      </div>
      <div id="team"></div>
    </div>
  );
}

export default Katalog;
