import "./Showcase.css";
import { Parallax } from "react-parallax";
import BgImage from "../../images/show-logo-dt.jpg";
import BgLogo from "../../images/DT_Logo_weiss.png";

function Showcase() {
  return (
    <div className="div" id="big-logo">
      <Parallax className="showcase" bgImage={BgImage} strength={800}>
        <div className="show">
          <div className="big-logo">
            <div>
              <img src={BgLogo} alt="big-logo-weiss" />
            </div>
          </div>
        </div>
      </Parallax>
    </div>
  );
}

export default Showcase;
