import "./Sidenav.css";
import { useState } from "react";

function Sidenav({ menuOpen, setMenuOpen }) {
  return (
    <div className={"sidenav " + (menuOpen && "active")}>
      <ul>
        <li className="sidenav-item">
          <a href="/#produkte" onClick={() => setMenuOpen(false)}>
            {" "}
            Produkte
          </a>
        </li>
        <li className="sidenav-item">
          <a href="/#team" onClick={() => setMenuOpen(false)}>
            Team
          </a>
        </li>
        <li className="sidenav-item">
          <a href="/#downloads" onClick={() => setMenuOpen(false)}>
            Downloads
          </a>
        </li>
        <li className="sidenav-item">
          <a href="/#aktionen" onClick={() => setMenuOpen(false)}>
            Aktionen
          </a>
        </li>
        <li className="sidenav-item">
          <a href="/#kontakt" onClick={() => setMenuOpen(false)}>
            Kontakt
          </a>
        </li>
      </ul>
    </div>
  );
}

export default Sidenav;
