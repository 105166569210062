import "./Team.css";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import CallUsSelector from "../CallUs/Callus";
import ChatBubbleLink from "../Bubble/Bubble";

function Team() {
  const [hideImage, setHideImage] = useState(false);
  const [hideImage1, setHideImage1] = useState(false);
  const [hideImage2, setHideImage2] = useState(false);
  const [hideImage3, setHideImage3] = useState(false);
  const [hideImage4, setHideImage4] = useState(false);
  const [hideImage5, setHideImage5] = useState(false);
  const [hideImage6, setHideImage6] = useState(false); // For Frau Zaiger
  const [hideImage7, setHideImage7] = useState(false); // For Herr Weyeneth

  const { ref, inView } = useInView({
    threshold: 0.2,
  });
  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start({
        opacity: 1,
        transition: {
          duration: 0.8,
        },
      });
    }
    if (!inView) {
      animation.start({
        opacity: 0,
      });
    }
  }, [inView]);

  return (
    <motion.div className="team" ref={ref} animate={animation}>
      <div className="team-header">
        <h2 className="blue">UNSER TEAM</h2>
        <h3 className="blue">AUS DER PRAXIS FÜR DIE PRAXIS</h3>
        <p>
          <span className="blue">Wir für Sie!</span> Bei uns vereinen sich
          Praxistätigkeiten in allen Bereichen der Zahnmedizin, IT-Wissen und
          Vertriebserfahrungen zu einer kompetenten und freundlichen
          Servicekultur. Wir beraten, begleiten und betreuen unsere Kunden
          persönlich, partnerschaftlich und individuell.
        </p>
      </div>

      <div className="team-images">
        <div className="team-images-first">
          <div
            onMouseEnter={() => setHideImage(true)}
            onMouseLeave={() => setHideImage(false)}
            className="team-image"
          >
            <div className="dirk">
              {!hideImage ? (
                <div></div>
              ) : (
                <div className="image-over">
                  <div className="team-image-wrap">
                    <h4>Dirk Tempelhagen</h4>
                    <p>Geschäftsführer</p>
                    <p>Mobil: 0175 41 78 885</p>
                    <p>Festnetz: 05823 4673 007</p>

                    <a href="mailto: d.tempelhagen@dt-dentalservice.de">
                      d.tempelhagen@dt-dentalservice.de
                    </a>
                    <ChatBubbleLink chat={887} />
                  </div>
                </div>
              )}
              <div className="chat"></div>
            </div>
          </div>

          <div
            onMouseEnter={() => setHideImage1(true)}
            onMouseLeave={() => setHideImage1(false)}
            className="team-image"
          >
            <div className="ma1">
              {!hideImage1 ? (
                <div></div>
              ) : (
                <div className="image-over">
                  <div className="team-image-wrap">
                    <h4>Michaela Will</h4>
                    <p>Medizinprodukteberaterin</p>
                    <p>Mobil: 0162 488 55 66</p>
                    <p>Festnetz: 05823 4673 000</p>

                    <a href="mailto: m.will@dt-dentalservice.de">
                      m.will@dt-dentalservice.de
                    </a>

                    <ChatBubbleLink chat={888} />
                  </div>
                </div>
              )}
            </div>
          </div>

          <div
            onMouseEnter={() => setHideImage2(true)}
            onMouseLeave={() => setHideImage2(false)}
            className="team-image"
          >
            <div className="ma2">
              {!hideImage2 ? (
                <div></div>
              ) : (
                <div className="image-over">
                  <div className="team-image-wrap">
                    <h4>Kinga Tempelhagen</h4>
                    <p>Logistik & Vertriebsinnendienst</p>
                    <p>Festnetz: 05823 4673 000</p>

                    <a href="mailto: k.tempelhagen@dt-dentalservice.de">
                      k.tempelhagen@dt-dentalservice.de
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="team-images-second">
          <div
            onMouseEnter={() => setHideImage3(true)}
            onMouseLeave={() => setHideImage3(false)}
            className="team-image"
          >
            <div className="ma3">
              {!hideImage3 ? (
                <div></div>
              ) : (
                <div className="image-over">
                  <div className="team-image-wrap">
                    <h4>René Dreßler</h4>
                    <p>Medizinprodukteberater</p>
                    <p>Mobil: 0173 789 18 88</p>

                    <a href="mailto: r.dressler@dt-dentalservice.de">
                      r.dressler@dt-dentalservice.de
                    </a>
                    <ChatBubbleLink chat={885} />
                  </div>
                </div>
              )}
            </div>
          </div>

          <div
            onMouseEnter={() => setHideImage4(true)}
            onMouseLeave={() => setHideImage4(false)}
            className="team-image"
          >
            <div className="ma4">
              {!hideImage4 ? (
                <div></div>
              ) : (
                <div className="image-over">
                  <div className="team-image-wrap">
                    <h4>Lara Schwarzrock</h4>
                    <p>QMB & Vertriebsinnendienst</p>

                    <p>Festnetz: 05823 4673 000</p>

                    <a href="mailto: l.schwarzrock@dt-dentalservice.de">
                      l.schwarzrock@dt-dentalservice.de
                    </a>
                    <ChatBubbleLink chat={881} />
                  </div>
                </div>
              )}
            </div>
          </div>

          <div
            onMouseEnter={() => setHideImage5(true)}
            onMouseLeave={() => setHideImage5(false)}
            className="team-image"
          >
            <div className="ma5">
              {!hideImage5 ? (
                <div></div>
              ) : (
                <div className="image-over">
                  <div className="team-image-wrap">
                    <h4>André Klingner</h4>
                    <p>Medizinprodukteberater</p>
                    <p>Mobil: 0173 273 04 77</p>

                    <a href="mailto: a.klingner@dt-dentalservice.de">
                      a.klingner@dt-dentalservice.de
                    </a>
                    <ChatBubbleLink chat={886} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="team-images-second">
          <div
            onMouseEnter={() => setHideImage7(true)}
            onMouseLeave={() => setHideImage7(false)}
            className="team-image"
          >
            <div className="ma6">
              {!hideImage7 ? (
                <div></div>
              ) : (
                <div className="image-over">
                  <div className="team-image-wrap">
                    <h4>René Weyeneth</h4>
                    <p>Partnerdepot in Celle</p>
                    <p>Medizinprodukteberater</p>
                    <p>Bereich: Celle</p>
                    <p>Festnetz: 05141 9801705</p>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div
            onMouseEnter={() => setHideImage6(true)}
            onMouseLeave={() => setHideImage6(false)}
            className="team-image"
          >
            <div className="ma7">
              {!hideImage6 ? (
                <div></div>
              ) : (
                <div className="image-over">
                  <div className="team-image-wrap">
                    <h4>Sabine Zaiger</h4>
                    <p>Partnerdepot in Stuttgart</p>
                    <p>Medizinprodukteberaterin</p>
                    <p>Bereich: Großraum Stuttgart</p>
                    <p>Mobil: 0170 586 55 70</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Team;
