import React from "react";

import "./Google.css";

import googleLogo from "../../images/google-bewertungen_weiss.png";

function Google() {
  const googleLink = "https://g.page/r/CSTi9R4HLZxfEAE/review";

  return (
    <div className="google">
      <a href={googleLink} target="_blank">
        <div className="google-wrapper google-over">
          <div className="google-header">
            <h3>BEWERTEN SIE UNS AUF</h3>
          </div>
          <div className="google-logo">
            <img src={googleLogo} alt="google-bewertungen-logo" />
          </div>
        </div>
      </a>
    </div>
  );
}

export default Google;
