import "./Gallery.css";
import { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleChevronLeft,
  faCircleChevronRight,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";

function Gallery({ galleryImages }) {
  const [hideImage, setHideImage] = useState(false);

  const [slideNumber, setSlideNumber] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  const [fotos, setFotos] = useState([]);

  const handleOpenModal = (event, index) => {
    setOpenModal(true);
    setFotos(galleryImages[index].node.fotos);
    console.log(galleryImages[index].node.fotos[slideNumber]);
  };

  // Close Modal
  const handleCloseModal = () => {
    setSlideNumber(0);
    setOpenModal(false);
  };

  // Previous Image
  const prevSlide = () => {
    slideNumber === 0
      ? setSlideNumber(fotos.length - 1)
      : setSlideNumber(slideNumber - 1);
  };

  // Next Image
  const nextSlide = () => {
    slideNumber + 1 === fotos.length
      ? setSlideNumber(0)
      : setSlideNumber(slideNumber + 1);
  };

  return (
    <div className="gallery">
      <h2>Veranstaltungen & Messen</h2>

      {openModal && (
        <div className="sliderWrap">
          <FontAwesomeIcon
            icon={faCircleXmark}
            className="btnClose"
            onClick={handleCloseModal}
          />
          <FontAwesomeIcon
            icon={faCircleChevronLeft}
            className="btnPrev"
            onClick={prevSlide}
          />
          <FontAwesomeIcon
            icon={faCircleChevronRight}
            className="btnNext"
            onClick={nextSlide}
          />
          <div className="fullScreenImage">
            <img src={fotos[slideNumber].url} alt="" />
          </div>
        </div>
      )}

      {/* <br />
  Current slide number:  {slideNumber}
  <br />
  Total Slides: {galleryImages.length}
  <br /><br /> */}

      <div className="galleryWrap">
        {galleryImages &&
          galleryImages.map((slide, index) => {
            return (
              <div
                className="imageWrap"
                onMouseEnter={() => setHideImage(true)}
                onMouseLeave={() => setHideImage(false)}
              >
                <div
                  className="single"
                  key={index}
                  onClick={(event) => handleOpenModal(event, index)}
                >
                  <img src={slide.node.frontFoto.url} alt="" />
                </div>

                {!hideImage ? (
                  <div></div>
                ) : (
                  <div className="textWrap">
                    <h4>{slide.node.title}</h4>
                    <p>{slide.node.datum}</p>
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default Gallery;
