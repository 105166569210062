import "./Impressum.css";
import Footer from "../../../components/footer/Footer";
import Sidenav from "../../../components/sidenav/Sidenav";
import SecNav from "../../../components/secNav/SecNav";
import { useState } from "react";

function Impressum() {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <>
      <SecNav menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <Sidenav menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <div className="impressum">
        <div className="impressum-header">
          <h2 className="blue">IMPRESSUM</h2>
        </div>
        <div className="impressum-info">
          <p>Für den Inhalt verantwortlich:</p>
          <p>DT Dental Service GmbH</p>
          <p>Neue Siedlung 34</p>
          <p>29553 Bienenbüttel/OT Rieste</p>
          <p>Deutschland</p>
          <br />
          <p>05823 46730 00</p>
          <br />
          <p>info@dt-dentalservice.de</p>
        </div>
        <div className="impressum-text">
          <h2 className="blue">RECHTLICHE HINWEISE</h2>
          <div className="haftung"></div>
          <p>(Haftungsausschluss):</p>
          <p>
            <span className="blue">Haftung für Inhalte: </span> Die Inhalte
            unserer Seiten wurden mit größter Sorgfalt erstellt. Für die
            Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir
            jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß §
            7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
            allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir
            als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
            gespeicherte fremde Informationen zu überwachen oder nach Umständen
            zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
            Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
            Informationen nach den allgemeinen Gesetzen bleiben hiervon
            unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
            Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
            Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
            Inhalte umgehend entfernen.
          </p>
          <p>
            <span className="blue">Haftung für Links: </span> Unser Angebot
            enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir
            keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte
            auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten
            ist stets der jeweilige Anbieter oder Betreiber der Seiten
            verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
            Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige
            Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine
            permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch
            ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar.
            Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links
            umgehend entfernen.
          </p>
          <p>
            <span className="blue">
              Hinweis zur verwendeten Warteschleifenmusik: www.ronaldkah.de
            </span>
          </p>
          <p>
            <span className="blue">Urheberrecht: </span> Die durch die
            Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten
            unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
            Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
            Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
            jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite
            sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
            Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
            wurden, werden die Urheberrechte Dritter beachtet. Insbesondere
            werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie
            trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten
            wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
            Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Impressum;
