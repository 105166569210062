import "./Info.css";
import CircularProgress from "./CircularProgress/CircularProgress";
import { useState } from "react";
import { useInView } from "react-intersection-observer";

function Info() {
  const [artikel, setArtikel] = useState(10.374);
  const [mitarbeiter, setMitarbeiter] = useState(6);
  const [einsatz, setEinsatz] = useState(100);

  const [decimals, setDecimals] = useState(0);
  const [suffix, setSuffix] = useState("");

  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  return (
    <div id="info">
      <div className="info-row">
        <div className="info-wrapper">
          <div className="info-header">
            <h2>WIR GEBEN ALLES ZUM THEMA DENTALPRODUKTE</h2>
            <p>UND MANCHMAL AUCH EIN BISSCHEN MEHR</p>
          </div>
          <div className="info-stats">
            <div className="stat" ref={ref}>
              {inView ? (
                <>
                  <CircularProgress
                    size={380}
                    strokeWidth={22}
                    percentage={100}
                    color="#fff"
                    end={artikel}
                    decimals={3}
                    suffix={suffix}
                  />
                </>
              ) : (
                <>
                  <CircularProgress
                    size={380}
                    strokeWidth={22}
                    percentage={0}
                    color="#fff"
                    end={0}
                    decimals={3}
                    suffix={suffix}
                  />
                </>
              )}
              <h3>ARTIKEL</h3>
              <div className="text-info">
                <p>
                  finden Sie in unserem Katalog, falls Sie nicht finden wonach
                  Sie suchen, helfen wir gern.
                </p>
              </div>
            </div>
            <div className="stat">
              {inView ? (
                <>
                  <CircularProgress
                    size={380}
                    strokeWidth={22}
                    percentage={100}
                    color="#fff"
                    end={mitarbeiter}
                    decimals={decimals}
                    suffix={suffix}
                  />
                </>
              ) : (
                <>
                  <CircularProgress
                    size={380}
                    strokeWidth={22}
                    percentage={0}
                    color="#fff"
                    end={0}
                    decimals={decimals}
                    suffix={suffix}
                  />
                </>
              )}
              <h3>MITARBEITER</h3>
              <div className="text-info">
                <p>
                  arbeiten bei DT Dental Service und kommen gerne bei Ihnen
                  vorbei.
                </p>
              </div>
            </div>
            <div className="stat">
              {inView ? (
                <>
                  <CircularProgress
                    size={380}
                    strokeWidth={22}
                    percentage={100}
                    color="#fff"
                    end={einsatz}
                    decimals={decimals}
                    suffix={"%"}
                  />
                </>
              ) : (
                <>
                  <CircularProgress
                    size={380}
                    strokeWidth={22}
                    percentage={0}
                    color="#fff"
                    end={0}
                    decimals={decimals}
                    suffix={"%"}
                  />
                </>
              )}

              <h3>LEIDENSCHAFT</h3>
              <div className="text-info">
                <p>dürfen Sie von uns erwarten, denn das ist unser Anspruch.</p>
              </div>
            </div>
          </div>
          <div id="produkte"></div>
        </div>
      </div>
    </div>
  );
}

export default Info;
