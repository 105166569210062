import "./Produkte.css";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";

function Produkte() {
  const [isShown, setIsShown] = useState(false);
  const [isShown1, setIsShown1] = useState(false);
  const [isShown2, setIsShown2] = useState(false);
  const [isShown3, setIsShown3] = useState(false);
  const [isShown4, setIsShown4] = useState(false);
  const [isShown5, setIsShown5] = useState(false);

  const { ref, inView } = useInView({
    threshold: 0.2,
  });
  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start({
        opacity: 1,
        transition: {
          duration: 0.8,
        },
      });
    }
    if (!inView) {
      animation.start({
        opacity: 0,
      });
    }
  }, [inView]);
  return (
    <>
      <motion.div ref={ref} className="produkte" animate={animation}>
        <div className="produkte-header">
          <h2 className="blue">UNSERE PRODUKTBEREICHE</h2>
          <h3 className="blue">
            SAGEN SIE UNS WAS SIE BRAUCHEN UND WIR FINDEN ES FÜR SIE
          </h3>
          <p>
            <span className="blue">Fordern Sie uns!</span> Hier zeigen wir Ihnen
            einen Überblick unseres Sortiments, welches wir fortlaufend,
            aufgrund der wachsenden Anforderungen unserer Kunden, erweitern.
            Blättern Sie gerne online in unserem{" "}
            <a href="/#downloads" className="katalog-link blue">
              {" "}
              Katalog
            </a>{" "}
            und scheuen Sie sich nicht, über das Gedruckte hinauszudenken: Meine
            Mitarbeiter und ich werden sich um jede Fragestellung kümmern und
            keiner Antwort schuldig bleiben.
          </p>
        </div>
        <div className="produkte-icons">
          <div className="produkte-icon">
            <div
              className="icon-wrap"
              onMouseEnter={() => setIsShown(true)}
              onMouseLeave={() => setIsShown(false)}
            >
              <div className="icon">
                {!isShown ? (
                  <div className="icon-prophy"></div>
                ) : (
                  <div className="icon-front-pro">
                    <div className="icon-front-text">
                      <h4>PROPHYLAXE</h4>
                      <ul>
                        <li>Kelche</li>
                        <li>Bürsten</li>
                        <li>Küretten & Scaler</li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div
              className="icon-wrap"
              onMouseEnter={() => setIsShown1(true)}
              onMouseLeave={() => setIsShown1(false)}
            >
              <div className="icon">
                {!isShown1 ? (
                  <div className="icon-bohrer"></div>
                ) : (
                  <div className="icon-front-boh">
                    <div className="icon-front-text">
                      <h4>BOHRER</h4>
                      <ul>
                        <li>Diamantschleifer und Finierer</li>
                        <li>Hartmetallbohrer</li>
                        <li>Laborfräser</li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div
              className="icon-wrap"
              onMouseEnter={() => setIsShown2(true)}
              onMouseLeave={() => setIsShown2(false)}
            >
              <div className="icon">
                {!isShown2 ? (
                  <div className="icon-poli"></div>
                ) : (
                  <div className="icon-front-pol">
                    <div className="icon-front-text">
                      <h4>POLIERER</h4>
                      <ul>
                        <li>Kunststoff</li>
                        <li>Keramik</li>
                        <li>Zirkon</li>
                        <li>Metall</li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="produkte-icon">
            <div
              className="icon-wrap"
              onMouseEnter={() => setIsShown3(true)}
              onMouseLeave={() => setIsShown3(false)}
            >
              <div className="icon">
                {!isShown3 ? (
                  <div className="icon-ins"></div>
                ) : (
                  <div className="icon-front-ins">
                    <div className="icon-front-text">
                      <h4>INSTRUMENTE</h4>
                      <ul>
                        <li>Chirurgie</li>
                        <li>Konservierend</li>
                        <li>Diagnostik</li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              className="icon-wrap"
              onMouseEnter={() => setIsShown4(true)}
              onMouseLeave={() => setIsShown4(false)}
            >
              <div className="icon">
                {!isShown4 ? (
                  <div className="icon-stif"></div>
                ) : (
                  <div className="icon-front-stif">
                    <div className="icon-front-text">
                      <h4>STIFTE</h4>
                      <ul>
                        <li>Titananker</li>
                        <li>Glasfaserstifte</li>
                        <li>parapulpäre Stifte</li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              className="icon-wrap"
              onMouseEnter={() => setIsShown5(true)}
              onMouseLeave={() => setIsShown5(false)}
            >
              <div className="icon">
                {!isShown5 ? (
                  <div className="icon-endo"></div>
                ) : (
                  <div className="icon-front-endo">
                    <div className="icon-front-text">
                      <h4>ENDO</h4>
                      <ul>
                        <li>Handfeilen</li>
                        <li>maschinelle Feilen</li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default Produkte;
