import "./Handy.css";
import { FaMobileAlt } from "react-icons/fa";

function Handy() {
  return (
    <div className="handy">
      <div>
        <div className="handy-wrapper handy-over">
          <div className="handy-icon">
            <FaMobileAlt />
          </div>
          <div className="handy-header">
            <a href="tel:04135 69 53 300">05823 46730 00</a>

            <h4>Gerne sind wir für Sie da</h4>
          </div>
        </div>
        <div id="kontakt"></div>
      </div>
    </div>
  );
}

export default Handy;
