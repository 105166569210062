import "./secNav.css";
import smallLogo from "../../images/s-logo.png";

function SecNav({ menuOpen, setMenuOpen }) {
  return (
    <div className="nav">
      <div className="s-logo">
        <a href="/#big-logo">
          <img src={smallLogo} alt="small-logo" className="dt-logo" />
        </a>
      </div>
      <div className="res-logo">
        <a href="/#header">
          <img src={smallLogo} alt="small-logo" className="dt-logo" />
        </a>
      </div>
      <div className="menu">
        <div className="menu-list">
          <ul>
            <li className="menu-item">
              <a href="/#produkte">
                {" "}
                Produkte <span className="menu-icon"></span>
              </a>
            </li>
            <li className="menu-item">
              <a href="/#team">
                Team <span className="menu-icon"></span>
              </a>
            </li>
            <li className="menu-item">
              <a href="/#downloads">
                Downloads <span className="menu-icon"></span>
              </a>
            </li>
            <li className="menu-item">
              <a href="/#aktionen">
                Aktionen <span className="menu-icon"></span>
              </a>
            </li>
            <li className="menu-item">
              <a href="/#kontakt">
                Kontakt <span className="menu-icon"></span>
              </a>
            </li>
          </ul>
        </div>
        <div
          className={!menuOpen ? "hamburger" : "hamburger active"}
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <div className="line1"></div>
          <div className="line2"></div>
          <div className="line3"></div>
        </div>
      </div>
    </div>
  );
}

export default SecNav;
