import "./Map.css";
import Google from "./google/Google";

function Map() {
  return (
    <div className="map">
      <Google />
    </div>
  );
}

export default Map;
