import "./Kontakt.css";
import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

function Kontakt() {
  const form = useRef();
  const [done, setDone] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [text, setText] = useState("");
  const [tel, setTel] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_z6mwp1h",
        "template_5m02okj",
        form.current,
        "user_rBqyeDZjKyFf6rddCecp2"
      )
      .then(
        (result) => {
          console.log(result.text);
          setDone(true);
        },
        (error) => {
          console.log(error.text);
        }
      );

    setName("");
    setEmail("");
    setText("");
    setTel("");
  };
  return (
    <div className="kontakt">
      <div className="kontakt-header">
        <h2 className="blue">KONTAKTIEREN SIE UNS</h2>
        <p className="kontakt-text blue">WIR FREUEN UNS VON IHNEN ZU HÖREN</p>
        <div className="kontakt-data">
          <p>DT Dental Service GmbH</p>
          <p>Neue Siedlung 34</p>
          <p>29553 Bienenbüttel/OT Rieste</p>
          <p>Telefon: 05823 46730 00</p>
        </div>
      </div>
      <div className="kontakt-form">
        <form ref={form} onSubmit={sendEmail}>
          <input
            type="text"
            name="user_name"
            id="name"
            placeholder="Name"
            value={name}
            required
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type="email"
            name="user_email"
            id="email"
            placeholder="E-Mail"
            value={email}
            required
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="number"
            name="phone"
            id="telefon"
            placeholder="Telefon (optional)"
            value={tel}
            onChange={(e) => setTel(e.target.value)}
          />
          <textarea
            id="nachricht"
            name="message"
            placeholder="Platz für Ihre Nachricht"
            value={text}
            required
            onChange={(e) => setText(e.target.value)}
          ></textarea>
          <input type="submit" value="Senden" className="btn-kontakt" />
          {done && (
            <p className="kontakt-success">
              Vielen Dank! Wir haben ihre Nachricht erhalten und melden uns bei
              Ihnen in Kürze.
            </p>
          )}
        </form>
      </div>
      <div className="kontakt-map"></div>
    </div>
  );
}

export default Kontakt;
