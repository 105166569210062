import "./Datenschutz.css";

import Footer from "../../../components/footer/Footer";
import Sidenav from "../../../components/sidenav/Sidenav";
import { useState } from "react";
import SecNav from "../../../components/secNav/SecNav";

function Datenschutz() {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <>
      <SecNav menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <Sidenav menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <div className="datenschutz">
        <h2 className="blue">DATENSCHUTZERKLÄRUNG</h2>
        <p>
          Die folgenden Hinweise geben einen einfachen Überblick darüber, was
          mit Ihren personenbezogenen Daten passiert, wenn Sie unsere Website
          besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
          persönlich identifiziert werden können. Ausführliche Informationen zum
          Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
          Datenschutzerklärung.
        </p>
        <br />
        <br />
        <br />
        <span className="datenschutz-text blue">
          Allgemeiner Hinweis und Pflichtinformationen
        </span>
        <span className="datenschutz-text blue">Verantwortlichen Stelle</span>
        <p>
          Die verantwortliche Stelle für die Datenverarbeitung auf dieser
          Website ist: DT Dental Service GmbH
        </p>
        <p>
          <span className="blue">Datenschutzbeauftragter: </span>Dirk
          Tempelhagen
        </p>
        <p>Neue Siedlung 34</p>
        <p>29553 Bienenbüttel/OT Rieste</p>
        <p>
          Die verantwortliche Stelle entscheidet allein oder gemeinsam mit
          anderen über die Zwecke und Mittel der Verarbeitung von
          personenbezogenen Daten (z.B. Namen, Kontaktdaten o. Ä.).
        </p>
        <p>
          <span className="blue">
            Widerruf Ihrer Einwilligung zur Datenverarbeitung:
          </span>{" "}
          Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der
          Datenverarbeitung möglich. Ein Widerruf Ihrer bereits erteilten
          Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine
          formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum
          Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
        </p>
        <p>
          <span className="blue">
            Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde:
          </span>{" "}
          Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen
          Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
          Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen
          ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der
          Sitz unseres Unternehmens befindet. Der folgende Link stellt eine
          Liste der Datenschutzbeauftragten sowie deren Kontaktdaten bereit:
          https://www.bfdi.bund.de/DE/Infothek/Anschriften_
          Links/anschriften_links-node.html.
        </p>
        <p>
          <span className="blue">Recht auf Datenübertragbarkeit:</span> Ihnen
          steht das Recht zu, Daten, die wir auf Grundlage Ihrer Einwilligung
          oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich
          oder an Dritte aushändigen zu lassen. Die Bereitstellung erfolgt in
          einem maschinenlesbaren Format. Sofern Sie die direkte Übertragung der
          Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur,
          soweit es technisch machbar ist.
        </p>
        <p>
          <span className="blue">
            Recht auf Auskunft, Berichtigung, Sperrung, Löschung:
          </span>{" "}
          Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen
          das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
          personenbezogenen Daten, Herkunft der Daten, deren Empfänger und den
          Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung,
          Sperrung oder Löschung dieser Daten. Diesbezüglich und auch zu
          weiteren Fragen zum Thema personenbezogene Daten können Sie sich
          jederzeit über die im Impressum aufgeführten Kontaktmöglichkeiten an
          uns wenden.
        </p>
        <p>
          <span className="blue">SSL- bzw. TLS-Verschlüsselung:</span> Aus
          Sicherheitsgründen und zum Schutz der Übertragung vertraulicher
          Inhalte, die Sie an uns als Seitenbetreiber senden, nutzt unsere
          Website eine SSL-bzw. TLS-Verschlüsselung. Damit sind Daten, die Sie
          über diese Website übermitteln, für Dritte nicht mitlesbar. Sie
          erkennen eine verschlüsselte Verbindung an der „https://“ Adresszeile
          Ihres Browsers und am Schloss-Symbol in der Browserzeile.
        </p>
        <p>
          <span className="blue">
            Datenübermittlung bei Vertragsschluss für Warenkauf und
            Warenversand:{" "}
          </span>
          Personenbezogene Daten werden nur an Dritte übermittelt, sofern eine
          Notwendigkeit im Rahmen der Vertragsabwicklung besteht. Dritte können
          beispielsweise Bezahldienstleister oder Logistikunternehmen sein. Eine
          weitergehende Übermittlung der Daten findet nicht statt bzw. nur dann,
          wenn Sie dieser ausdrücklich zugestimmt haben.
        </p>
        <p>
          Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO,
          der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder
          vorvertraglicher Maßnahmen gestattet.
        </p>
        <p>
          Per Kontaktformular übermittelte Daten werden einschließlich Ihrer
          Kontaktdaten gespeichert, um Ihre Anfrage bearbeiten zu können oder um
          für Anschlussfragen bereitzustehen. Eine Weitergabe dieser Daten
          findet ohne Ihre Einwilligung nicht statt.
        </p>
        <p>
          Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt
          ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a
          DSGVO). Ein Widerruf Ihrer bereits erteilten Einwilligung ist
          jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung
          per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
          Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
        </p>
        <p>
          Über das Kontaktformular übermittelte Daten verbleiben bei uns, bis
          Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung
          widerrufen oder keine Notwendigkeit der Datenspeicherung mehr besteht.
          Zwingende gesetzliche Bestimmungen - insbesondere Aufbewahrungsfristen
          - bleiben unberührt.
        </p>
        <p>
          <span className="blue">Newsletter-Daten: </span>Zum Versenden unseres
          Newsletters benötigen wir von Ihnen eine E-Mail-Adresse. Eine
          Verifizierung der angegebenen E-Mail-Adresse ist notwendig und der
          Empfang des Newsletters ist einzuwilligen. Ergänzende Daten werden
          nicht erhoben oder sind freiwillig. Die Verwendung der Daten erfolgt
          ausschließlich für den Versand des Newsletters.
        </p>
        <p>
          Die bei der Newsletteranmeldung gemachten Daten werden ausschließlich
          auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO)
          verarbeitet. Ein Widerruf Ihrer bereits erteilten Einwilligung ist
          jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung
          per E-Mail oder Sie melden sich über den „Austragen“-Link im
          Newsletter ab. Die Rechtmäßigkeit der bereits erfolgten
          Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
        </p>
        <p>
          Zur Einrichtung des Abonnements eingegebene Daten werden im Falle der
          Abmeldung gelöscht. Sollten diese Daten für andere Zwecke und an
          anderer Stelle an uns übermittelt worden sein, verbleiben diese
          weiterhin bei uns.
        </p>
        <p>
          <span className="blue">Cookies: </span> Unsere Website verwendet
          Cookies. Das sind kleine Textdateien, die Ihr Webbrowser auf Ihrem
          Endgerät speichert. Cookies helfen uns dabei, unser Angebot
          nutzerfreundlicher, effektiver und sicherer zu machen. Einige Cookies
          sind “Session-Cookies.” Solche Cookies werden nach Ende Ihrer
          Browser-Sitzung von selbst gelöscht. Hingegen bleiben andere Cookies
          auf Ihrem Endgerät bestehen, bis Sie diese selbst löschen. Solche
          Cookies helfen uns, Sie bei Rückkehr auf unserer Website
          wiederzuerkennen.
        </p>
        <p>
          Mit einem modernen Webbrowser können Sie das Setzen von Cookies
          überwachen, einschränken oder unterbinden. Viele Webbrowser lassen
          sich so konfigurieren, dass Cookies mit dem Schließen des Programms
          von selbst gelöscht werden. Die Deaktivierung von Cookies kann eine
          eingeschränkte Funktionalität unserer Website zur Folge haben.
        </p>
        <p>
          Das Setzen von Cookies, die zur Ausübung elektronischer
          Kommunikationsvorgänge oder der Bereitstellung bestimmter, von Ihnen
          erwünschter Funktionen (z.B. Warenkorb) notwendig sind, erfolgt auf
          Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Als Betreiber dieser Website
          haben wir ein berechtigtes Interesse an der Speicherung von Cookies
          zur technisch fehlerfreien und reibungslosen Bereitstellung unserer
          Dienste. Sofern die Setzung anderer Cookies (z.B. für
          Analyse-Funktionen) erfolgt, werden diese in dieser
          Datenschutzerklärung separat behandelt.
        </p>
        <p>
          <span className="blue">Google Analytics: </span> Unsere Website
          verwendet Funktionen des Webanalysedienstes Google Analytics. Anbieter
          des Webanalysedienstes ist die Google Inc., 1600 Amphitheatre Parkway,
          Mountain View, CA 94043, USA.
        </p>
        <p>
          Google Analytics verwendet „Cookies.“ Das sind kleine Textdateien, die
          Ihr Webbrowser auf Ihrem Endgerät speichert und eine Analyse der
          Website-Benutzung ermöglichen. Mittels Cookie erzeugte Informationen
          über Ihre Benutzung unserer Website werden an einen Server von Google
          übermittelt und dort gespeichert. ServerStandort ist im Regelfall die
          USA.
        </p>
        <p>
          Das Setzen von Google-Analytics-Cookies erfolgt auf Grundlage von Art.
          6 Abs. 1 lit. f DSGVO. Als Betreiber dieser Website haben wir ein
          berechtigtes Interesse an der Analyse des Nutzerverhaltens, um unser
          Webangebot und ggf. auch Werbung zu optimieren
        </p>
        <p>
          <span className="blue">IP-Anonymisierung: </span> Wir setzen Google
          Analytics in Verbindung mit der Funktion IP-Anonymisierung ein. Sie
          gewährleistet, dass Google Ihre IP-Adresse innerhalb von
          Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten
          des Abkommens über den Europäischen Wirtschaftsraum vor der
          Übermittlung in die USA kürzt. Es kann Ausnahmefälle geben, in denen
          Google die volle IP-Adresse an einen Server in den USA überträgt und
          dort kürzt. In unserem Auftrag wird Google diese Informationen
          benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über
          Websiteaktivitäten zu erstellen und um weitere mit der Websitenutzung
          und der Internetnutzung verbundene Dienstleistungen gegenüber uns zu
          erbringen. Es findet keine Zusammenführung der von Google Analytics
          übermittelten IP-Adresse mit anderen Daten von Google statt.
        </p>
        <p>
          <span className="blue">Browser Plugin: </span>Das Setzen von Cookies
          durch Ihren Webbrowser ist verhinderbar. Einige Funktionen unserer
          Website könnten dadurch jedoch eingeschränkt werden. Ebenso können Sie
          die Erfassung von Daten bezüglich Ihrer Website-Nutzung einschließlich
          Ihrer IP-Adresse mitsamt anschließender Verarbeitung durch Google
          unterbinden. Dies ist möglich, indem Sie das über folgenden Link
          erreichbare Browser-Plugin herunterladen und installieren:
          https://tools.google.com/dlpage/gaoptout?hl=de
        </p>
        <p>
          <span className="blue">Widerspruch gegen die Datenerfassung: </span>{" "}
          Sie können die Erfassung Ihrer Daten durch Google Analytics
          verhindern, indem Sie auf folgenden Link klicken. Es wird ein
          Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei zukünftigen
          Besuchen unserer Website verhindert: Google Analytics deaktivieren.
        </p>
        <p>
          Einzelheiten zum Umgang mit Nutzerdaten bei Google Analytics finden
          Sie in der Datenschutzerklärung von Google:
          https://support.google.com/analytics/answer/6004245?hl=de
        </p>
        <p>
          Auftragsverarbeitung Zur vollständigen Erfüllung der gesetzlichen
          Datenschutzvorgaben haben wir mit Google einen Vertrag über die
          Auftragsverarbeitung abgeschlossen.
        </p>
        <p>
          <span className="blue">
            Demografische Merkmale bei Google Analytics:{" "}
          </span>
          Unsere Website verwendet die Funktion “demografische Merkmale” von
          Google Analytics. Mit ihr lassen sich Berichte erstellen, die Aussagen
          zu Alter, Geschlecht und Interessen der Seitenbesucher enthalten.
          Diese Daten stammen aus interessenbezogener Werbung von Google sowie
          aus Besucherdaten von Drittanbietern. Eine Zuordnung der Daten zu
          einer bestimmten Person ist nicht möglich. Sie können diese Funktion
          jederzeit deaktivieren. Dies ist über die Anzeigeneinstellungen in
          Ihrem Google-Konto möglich oder indem Sie die Erfassung Ihrer Daten
          durch Google Analytics, wie im Punkt “Widerspruch gegen die
          Datenerfassung” erläutert, generell untersagen
        </p>
        <p>
          <span className="blue">Google Web Fonts: </span>Unsere Website
          verwendet Web Fonts von Google. Anbieter ist die Google Inc., 1600
          Amphitheatre Parkway, Mountain View, CA 94043, USA
        </p>
        <p>
          Durch den Einsatz dieser Web Fonts wird es möglich Ihnen die von uns
          gewünschte Darstellung unserer Website zu präsentieren, unabhängig
          davon welche Schriften Ihnen lokal zur Verfügung stehen. Dies erfolgt
          über den Abruf der Google Web Fonts von einem Server von Google in den
          USA und der damit verbundenen Weitergabe Ihre Daten an Google. Dabei
          handelt es sich um Ihre IP-Adresse und welche Seite Sie bei uns
          besucht haben. Der Einsatz von Google Web Fonts erfolgt auf Grundlage
          von Art. 6 Abs. 1 lit. f DSGVO. Als Betreiber dieser Website haben wir
          ein berechtigtes Interesse an der optimalen Darstellung und
          Übertragung unseres Webauftritts.
        </p>
        <p>
          Das Unternehmen Google ist für das EU-US-Datenschutzabkommen „Privacy
          Shield“ zertifiziert. Dieses Datenschutzabkommen soll die Einhaltung
          des in der EU geltenden Datenschutzniveaus gewährleisten.
        </p>
        <p>
          Einzelheiten über Google Web Fonts finden Sie unter:
          https://www.google.com/fonts#AboutPlace:about und weitere
          Informationen in den Datenschutzbestimmungen von Google:
          https://policies.google.com/privacy/partners?hl=de
        </p>
      </div>
      <Footer />
    </>
  );
}

export default Datenschutz;
