import "./Home.css";
import Showcase from "../../components/showcase/Showcase";
import Nav from "../../components/nav/Nav";
import Header from "../../components/header/Header";
import Info from "../../components/info/Info";
import Produkte from "../../components/produkte/Produkte";
import Katalog from "../../components/katalog/Katalog";
import Team from "../../components/team/Team";
import Newsletter from "../../components/newsletter/Newsletter";
import Downloads from "../../components/downloads/Downloads";
import DtShop from "../../components/dt-shop/DtShop";
import Aktionen from "../../components/aktionen/Aktionen";
import Handy from "../../components/handy/Handy";
import Kontakt from "../../components/kontakt/Kontakt";
import Map from "../../components/map/Map";
import Footer from "../../components/footer/Footer";
import Sidenav from "../../components/sidenav/Sidenav";
import Gallery from "../../components/gallery/Gallery";
import Google from "../../components/google/Google";
import { Link } from "react-router-dom";

import { getData } from "../../services";

import { useEffect, useState } from "react";
import AktionenBild from "../../components/aktionenBild/AktionenBild";
import CookieConsent from "react-cookie-consent";
import ChatModal from "../../components/modal/Modal";
import ChatPopup from "../../components/ChatPopup/ChatPopup";
import CallUsSelector from "../../components/CallUs/Callus";

function Home() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [newData, setNewData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await getData();

      setNewData(result);
      console.log(result);
    };

    fetchData();
  }, []);

  return (
    <div>
      <Showcase />
      <Nav menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <Sidenav menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <Header />
      <Info />
      <Produkte />
      <Katalog />
      <Team />
      <Newsletter />
      <Downloads />
      <DtShop />
      <Gallery galleryImages={newData} />
      <Google />
      <Aktionen />
      <AktionenBild />
      <Handy />
      <Kontakt />
      <Map />
      <Footer />
      <CookieConsent
        location="top"
        style={{
          backgroundColor: "#395693",
          padding: "10px",
          lineHeight: "1.5",
          zIndex: "12121212121",
        }}
        buttonText="Akzeptieren"
        buttonStyle={{ fontWeight: "700", color: "#395693", padding: "10px" }}
        expires={30}
      >
        Diese Internetseite verwendet Cookies und Google Analytics für die
        Analyse und Statistik. Wir nutzen Cookies zu unterschiedlichen Zwecken,
        unter anderem zur Analyse und für personalisierte
        Marketing-Mitteilungen. Durch die weitere Nutzung der Website stimmen
        Sie der Verwendung zu.{" "}
        <Link to="/datenschutz" style={{ color: "#fff", fontWeight: "700" }}>
          Datenschutzerklärung
        </Link>{" "}
      </CookieConsent>
      {/* <ChatPopup /> */}
      <CallUsSelector />
    </div>
  );
}

export default Home;
