import React from "react";

import "./AktionenBild.css";

import aktionenBild from "../../images/aktion_2022.jpg";

function AktionenBild() {
  return (
    <div className="aktionenBild">
      <div className="image">
        <img src={aktionenBild} alt="" />
      </div>
    </div>
  );
}

export default AktionenBild;
