import "./Newsletter.css";
import { BsFillEnvelopeOpenFill } from "react-icons/bs";

function Newsletter() {
  return (
    <div className="newsletter">
      <div className="newsletter-wrapper newsletter-over">
        <div className="newsletter-icon">
          <BsFillEnvelopeOpenFill />
        </div>
        <div className="newsletter-header">
          <h3>NEWSLETTER</h3>
          <h4>NICHTS VERPASSEN UND 5€-GUTSCHEIN SICHERN</h4>
        </div>
      </div>
      <div id="downloads"></div>
    </div>
  );
}

export default Newsletter;
