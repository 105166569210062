import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import { GiPositionMarker } from "react-icons/gi";
import "./Google.css";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class Google extends Component {
  static defaultProps = {
    center: {
      lat: 53.11325,
      lng: 10.45192,
    },
    zoom: 15,
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: "580px", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyCSnKljo1hUUkUkOTLIySzB6H14TC-g_pI" }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <GiPositionMarker
            className="marker"
            lat={53.11325}
            lng={10.45192}
            text="DT-Dentalservice"
          />
        </GoogleMapReact>
      </div>
    );
  }
}

export default Google;
