import React from "react";
import { FaCommentDots } from "react-icons/fa"; // Import the desired icon
import "./Bubble.css"; // Import your custom CSS

const ChatBubbleLink = ({ chat }) => {
  if (!chat) {
    return null;
  }

  return (
    <a
      href={`https://siptel.dt-dentalservice.de/${chat}`}
      className="chat-bubble-link"
    >
      <FaCommentDots size={32} /> {/* Icon with size 30 */}
    </a>
  );
};

export default ChatBubbleLink;
