import "./App.css";
import Home from "./pages/home/Home";
import Impressum from "./pages/home/impressum/Impressum";
import Datenschutz from "./pages/home/datenschutz/Datenschutz";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollToTop from "./pages/ScrollToTop";
import { Helmet } from "react-helmet";

function App() {
  return (
    <Router>
      <div className="App">
        <Helmet>
          <title>
            DT-Dental Service Webseite - Service ist unsere Leidenschaft
          </title>
          <meta
            name="IHR KOMPETENTER PARTNER FÜR DEN DENTALBEDARF"
            content="DT - Dental Service"
            charSet="utf-8"
          />
        </Helmet>
        <ScrollToTop>
          <Routes>
            <Route exact path="/" element={<Home />}></Route>
            <Route path="/impressum" element={<Impressum />}></Route>
            <Route path="/datenschutz" element={<Datenschutz />}></Route>
          </Routes>
        </ScrollToTop>
      </div>
    </Router>
  );
}

export default App;
